<template>
  <div class="flex items-center">
    <div v-if="label" class="label">{{ label }}</div>
    <div class="flex items-center mr2">
      <Input
        :value="value"
        class="base-branding-item__input"
        @input="$event.target.value.length === 7 && $emit('change', $event.target.value)"
      />
      <div class="relative">
        <div
          @click="colorPickerOpen = true"
          class="base-branding-item__color"
          :style="{ background: value }"
        ></div>
        <ColorPicker
          ref="colorPicker"
          v-if="colorPickerOpen"
          theme="light"
          :color="value"
          :sucker-canvas="null"
          :sucker-area="[]"
          @changeColor="changeColor"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, defineComponent } from 'vue';
  import { onClickOutside } from '@vueuse/core';
  import { useDebounceFn } from '@vueuse/core';

  export default defineComponent({
    name: 'BaseBrandingItem',
    props: {
      value: {
        type: String,
        default: 'ffffff'
      },
      name: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      }
    },
    emits: ['change'],
    setup(props, { emit }) {
      const colorPickerOpen = ref(false);
      const colorPicker = ref(null);

      const changeColor = useDebounceFn(data => emit('change', data.hex), 200);

      onClickOutside(colorPicker, () => (colorPickerOpen.value = false));

      return { colorPicker, colorPickerOpen, changeColor };
    }
  });
</script>

<style scoped lang="scss">
  .base-branding-item {
    &__input {
      height: global-vars(ui-default-measure4x);
      width: 100px;
      margin-right: global-vars(ui-default-measure);
    }

    &__color {
      width: 30px;
      height: 30px;
    }
  }

  .label {
    text-transform: capitalize;
    @include font(15px, 500, 21px);
    width: 300px;
  }
</style>
